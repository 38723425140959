/* prettier-ignore */

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.
// scss-docs-start container-max-widths
$container-max-widths: () !default;
$container-max-widths: map-merge(
  (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1480px,
  ),
  $container-max-widths
);

$grid-breakpoints: () !default;
$grid-breakpoints: map-merge(
  (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1540px,
  ),
  $grid-breakpoints
);

//*-----------------------------------------------
//|   Gutter
//-----------------------------------------------*/
$grid-gutter-width: 2rem !default;

//*-----------------------------------------------
//|   Color System
//-----------------------------------------------*/

$white: #fff !default;
$gray-100:#F5F7FA  !default;
$gray-200: #EBEBED !default;
$gray-300: #E1E0E4 !default;
$gray-400: #D7D6DA !default;
$gray-500: #CDCCD1 !default;
$gray-600:  #C3C1C8 !default;
$gray-700:  #7D7987 !default;
$gray-800: #616368 !default;
$gray-900: #4F5665 !default;
$gray-1000: #242F51 !default;
$gray-1100: #1F1534 !default;
$black: #000 !default;

$grays: () !default;
$grays: map-merge(
  (
    'black': $black,
    '100': $gray-100,
    '200': $gray-200,
    '300': $gray-300,
    '400': $gray-400,
    '500': $gray-500,
    '600': $gray-600,
    '700': $gray-700,
    '800': $gray-800,
    '900': $gray-900,
    '1000': $gray-1000,
    '1100': $gray-1100,
    'white': $white,
  ),
  $grays
);

//*-----------------------------------------------
//|   Solid Colors
//-----------------------------------------------*/
$blue: #2500F9 !default;
$indigo: #727cf5 !default;
$purple: #ad45f6 !default;
$pink: #f6458e !default;
$red: #F55767 !default;
$orange: #f6d845 !default;
$yellow: #FCAE61 !default;
$green: #40975F !default;
$teal: #299EF3 !default;
$cyan: #38B0F5 !default;
$light: #E2EAED !default;

//*-----------------------------------------------
//|   Theme Colors
//-----------------------------------------------*/
$primary: $blue !default;
$secondary: $gray-900 !default;
$success: $green !default;
$info: $purple !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $light !default;
$dark: $gray-1100 !default;

$theme-colors: () !default;
$theme-colors: map-merge(
  (
    'primary': $primary,
    'secondary': $secondary,
    'success': $success,
    'info': $info,
    'warning': $warning,
    'danger': $danger,
    'light': $light,
    'dark': $dark,
  ),
  $theme-colors
);
$theme-color-interval: 8% !default;

$theme-colors-soft: () !default;
$theme-colors-soft: map-merge(
  (
    'primary': tint-color($primary, 90),
    'secondary': tint-color($secondary, 90),
    'success': tint-color($success, 90),
    'info': tint-color($info, 90),
    'warning': tint-color($warning, 90),
    'danger': tint-color($danger, 90),
    'light': tint-color($light, 90),
    'dark': tint-color($dark, 90),
  ),
  $theme-colors-soft
);

// Customize the light and dark text colors for use in our color contrast function.
$color-contrast-dark: $gray-800 !default;

// Min contrast ratio
$min-contrast-ratio: 2 !default;

//*-----------------------------------------------
//|   Brand colors
//-----------------------------------------------*/
$linkedin: #0077b5 !default;
$facebook: #3c5a99 !default;
$twitter: #1da1f2 !default;
$google-plus: #dd4b39 !default;
$github: #333 !default;
$youtube: #ff0000 !default;

$brand-colors: () !default;
$brand-colors: map-merge(
  (
    'facebook': $facebook,
    'google-plus': $google-plus,
    'twitter': $twitter,
    'linkedin': $linkedin,
    'youtube': $youtube,
    'github': $github,
  ),
  $brand-colors
);

//*-----------------------------------------------
//|   Border
//-----------------------------------------------*/
$border-color: $gray-300 !default;
$border-width: 1px !default;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-shadows: false !default;
$enable-gradients: false !default;
$enable-negative-margins: true !default;
$enable-rfs:           false !default;

//*-----------------------------------------------
//|   Shadow
//-----------------------------------------------*/

$box-shadow: 0 .6375rem .975rem 0 #F4F8FE, 0 3px 6px 0 #F4F8FE !default;
$box-shadow-sm: 0 1.5625rem 2.8125rem rgba($gray-500, .075) !default;
$box-shadow-lg: 0 1rem 4rem rgba($black, 0.175) !default;

//*-----------------------------------------------
//|   Spacing
//----------------------------------------------

$spacer: 1rem !default;
$spacers: () !default;

$spacers: map-merge(
  (
    0: 0,
    1: $spacer * 0.25,
    2: $spacer * 0.5,
    3: $spacer,
    4: $spacer * 1.8,
    5: $spacer * 3,
    6: $spacer * 4,
    7: $spacer * 5,
    8: $spacer * 7.5,
    9: $spacer * 10,
    10: $spacer * 12.5,
    11: $spacer * 15
  ),
  $spacers
);

//*-----------------------------------------------
//|   Body
//-----------------------------------------------*/
$body-bg: $white !default;
$body-color: $gray-800 !default;

//*-----------------------------------------------
//|   Link
//-----------------------------------------------*/
$link-decoration: none !default;
$link-hover-decoration: underline !default;

//*-----------------------------------------------
//|   Components
//-----------------------------------------------*/
$border-radius: 0.25rem !default;
$border-radius-sm: 0.625rem !default;
$border-radius-lg: 1.25rem !default;

//*-----------------------------------------------
//|   Fonts Families
//-----------------------------------------------*/
$font-family-sans-serif: 'Poppins', 'Open Sans', -apple-system, BlinkMacSystemFont,
  'Segoe UI', 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji',
  'Segoe UI Emoji', 'Segoe UI Symbol' !default;
$font-family-monospace: 'SFMono-Regular', Menlo, Monaco, Consolas,
  'Liberation Mono', 'Courier New', monospace !default;
$font-family-base: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI',
   'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji',
  'Segoe UI Emoji', 'Segoe UI Symbol' !default;

//*-----------------------------------------------
//|   Fonts
//-----------------------------------------------*/
$type-scale: 1.2;
$font-size-base: 1rem !default;
$font-sizes: () !default;
$font-sizes: map-merge(
  (
    '-2': 1 / pow($type-scale, 2) * $font-size-base,
    '-1': 1 / $type-scale * $font-size-base,
    0: $font-size-base,
    1: pow($type-scale, 1) * $font-size-base,
    2: pow($type-scale, 2) * $font-size-base,
    3: pow($type-scale, 3) * $font-size-base,
    4: pow($type-scale, 4) * $font-size-base,
    5: pow($type-scale, 5) * $font-size-base,
    6: pow($type-scale, 6) * $font-size-base,
    7: pow($type-scale, 7) * $font-size-base,
    8: pow($type-scale, 8) * $font-size-base,
  ),
  $font-sizes
);

$font-size-sm: $font-size-base * 0.875 !default;
$font-size-lg: $font-size-base * 1.2 !default;
$font-weight-thin: 100 !default;
$font-weight-lighter: 200 !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-medium: 500 !default;
$font-weight-semi-bold: 600 !default;
$font-weight-bold: 700 !default;
$font-weight-bolder: 800 !default;
$font-weight-black: 900 !default;

$h1-font-size: map_get($font-sizes, 5) !default;
$h2-font-size: map_get($font-sizes, 4) !default;
$h3-font-size: map_get($font-sizes, 3) !default;
$h4-font-size: map_get($font-sizes, 2) !default;
$h5-font-size: map_get($font-sizes, 1) !default;
$h6-font-size: map_get($font-sizes, '-1') !default;

$headings-font-family: $font-family-sans-serif !default;
$headings-font-weight: $font-weight-medium !default;
$headings-color: $gray-1000 !default;

// scss-docs-start display-headings
$display-font-sizes: () !default;
$display-font-sizes: map-merge(
  (
    1: map_get($font-sizes, 8),
    2: map_get($font-sizes, 7),
    3: map_get($font-sizes, 6),
    4: map_get($font-sizes, 5),
    5: map_get($font-sizes, 4),
    6: map_get($font-sizes, 3),
  ),
  $display-font-sizes
);

$display-font-weight: $font-weight-black !default;
$display-line-height: 1 !default;
// scss-docs-end display-headings

$lead-font-size: $font-size-lg !default;
$lead-font-weight: $font-weight-normal !default;

$small-font-size: 75% !default;

$text-muted: $gray-500 !default;

$blockquote-font-size: $font-size-lg !default;



//*-----------------------------------------------
//|   Buttons and Forms
//-----------------------------------------------*/
$input-btn-padding-y: .56rem !default;
$input-btn-padding-x: .80rem !default;

$input-btn-padding-y-sm: .10rem !default;
$input-btn-padding-x-sm: 0.5rem !default;

$input-btn-padding-y-lg: .6rem !default;
$input-btn-padding-x-lg: 1.8rem !default;
$input-btn-font-size-lg:  $font-size-base !default;

$btn-reveal-hover-shadow: 0 0 0 1px rgba(43, 45, 80, 0.1),
  0 2px 5px 0 rgba(43, 45, 80, 0.08), 0 1px 1.5px 0 rgba($black, 0.07),
  0 1px 2px 0 rgba($black, 0.08) !default;
$btn-font-weight: $font-weight-medium !default;
$btn-focus-width: 0 !default;

// Allows for customizing button radius independently from global border radius
$btn-border-radius: 0.313rem !default;
$btn-border-radius-lg: .625rem !default;

//*-----------------------------------------------
//|   Navigation
//-----------------------------------------------*/
$navbar-font-size: $font-size-base * 0.8 !default;

$navbar-light-color: rgba($black, 0.95) !default;
$navbar-light-hover-color: rgba($black, 0.95) !default;
$navbar-light-active-color: $dark !default;
$navbar-light-disabled-color: rgba($black, 0.25) !default;

$navbar-dark-color: rgba($white, .7) !default;
$navbar-dark-hover-color: rgba($white, 0.9) !default;

$navbar-padding-y: map_get($spacers, 2) !default;
$navbar-padding-x: $spacer !default;
$top-nav-height: 4.3125rem !default;
$standard-nav-height: 3.5625rem !default;

$navbar-light-toggler-icon-bg: str-replace(
  url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-light-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M0 6h30M0 14h30M0 22h30'/%3E%3C/svg%3E"),
  '#',
  '%23'
) !default;



// /*-----------------------------------------------
//|   Navbar Brand
//-----------------------------------------------*/
$navbar-brand-font-size: map_get($font-sizes, 3) !default;
$navbar-brand-font-weight: $font-weight-bolder !default;

//*-----------------------------------------------
//|   Viewport Heights & Widths
//----------------------------------------------

$viewport-heights: () !default;
$viewport-heights: map-merge(
  (
    25: 25vh,
    50: 50vh,
    75: 75vh,
    100: 100vh,
  ),
  $viewport-heights
);

$viewport-widths: () !default;
$viewport-widths: map-merge(
  (
    25: 25vw,
    50: 50vw,
    75: 75vw,
    100: 100vw,
  ),
  $viewport-widths
);

$sizes: () !default;
$sizes: map-merge(
  (
    25: 25%,
    50: 50%,
    75: 75%,
    100: 100%,
    auto: auto,
  ),
  $sizes
);

// /*-----------------------------------------------
//|   Spinner
//-----------------------------------------------*/
$hr-color: $border-color !default;
$hr-opacity: 1 !default;

//*-----------------------------------------------
//|   card
//-----------------------------------------------*/
$card-border-width:                 2px !default;
$card-border-radius:                0.625rem !default;
$card-cap-bg:                        tint-color($gray-500, 90%) !default;


//*-----------------------------------------------
//|   carousel
//-----------------------------------------------*/
$carousel-control-opacity: 0.9 !default;
$carousel-control-hover-opacity: 0.9 !default;
$carousel-control-color:             $light !default;

$carousel-indicator-active-bg:       $primary !default;
$carousel-indicator-width:           9px !default;
$carousel-indicator-height:          9px !default;
$carousel-indicator-hit-area-height : 0 !default;


$carousel-control-prev-icon-bg:      url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='#{$carousel-control-color}' class='bi bi-arrow-left-circle-fill' viewBox='0 0 16 16'%3E%3Cpath d='M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z'/%3E%3C/svg%3E") !default;
$carousel-control-next-icon-bg:      url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='#{$carousel-control-color}' class='bi bi-arrow-right-circle-fill' viewBox='0 0 16 16'%3E%3Cpath d='M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z'/%3E%3C/svg%3E") !default;


// Accordion
$accordion-padding-y:                     1.5rem !default;
$accordion-padding-x:                     2.6rem !default;
$accordion-bg:                            $gray-100 !default;
$accordion-border-width:                  $border-width !default;
$accordion-border-color:                  rgba($white, .125) !default;
$accordion-border-radius:                 0 !default;

$accordion-button-active-bg:              $gray-100 !default;
$accordion-button-active-color:           $dark !default;

$accordion-button-focus-border-color:     $white !default;
$accordion-button-focus-box-shadow:       $light !default;

$accordion-icon-width:                    1.8rem !default;
$accordion-icon-active-color:             $dark !default;

$accordion-button-icon:         url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-plus' viewBox='0 0 16 16'%3E%3Cpath d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z'/%3E%3C/svg%3E") !default;
$accordion-button-active-icon:  url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-dash' viewBox='0 0 16 16'%3E%3Cpath d='M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z'/%3E%3C/svg%3E") !default;